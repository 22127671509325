import React, { useState, useEffect } from 'react'
import { observer } from "mobx-react"
import _ from 'lodash'

import { useStores } from '../../hooks/useStores'
import PermissionsDropDown from './PermissionsDropDown'
import PermissionListWithToggles from '../reuseable/PermissionListWithToggles'
import { CancelAndSaveButtons } from '../reuseable/CancelAndSaveButtons'

const RolePermission = observer(() => {

  const { permissionsStore, organizationStore } = useStores()

  const [ selectedRole, setSelectedRole ] = useState('OPERATOR')
  const [ allRolesForOrg, setAllRolesForOrg ] = useState([])
  const [ permissions, setPermissions ] = useState([])
  const [ permissionswithChanges, setPermissionsWithChanges ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ addChanges, setAddChanges ] = useState([])
  const [ removeChanges, setRemoveChanges ] = useState([])
  const [ newRole, setNewRole ] = useState(null)

  const load = async () => {
    setLoading(true)
    if (permissionsStore.systemPermissions.length === 0) {
      await permissionsStore.getSystemPermissions()
    }
    await permissionsStore.getOrgPermissions(organizationStore.selected) 
    await permissionsStore.getRolePermissions(organizationStore.selected)
    setLoading(false)
  }

  const cancel = () => {
    setAddChanges([])
    setRemoveChanges([])
  }

  useEffect(async () => {
    cancel()
    await load()
  }, [organizationStore.selected])
 
  useEffect(() => {
    const found = permissionsStore.rolePermissions && permissionsStore.rolePermissions.find(p => p.role === selectedRole)
    const permissions = found ? found.permissions : []
    setPermissions(permissions)
  }, [ selectedRole, permissionsStore.rolePermissions])

  useEffect(() => {
    const allRoles = permissionsStore.rolePermissions && permissionsStore.rolePermissions.map(p => p.role)
    setAllRolesForOrg(allRoles)
  }, [ permissionsStore.rolePermissions ])

  const save = async ( ) => {
    setLoading(true)
    const changes = { add: addChanges, remove: removeChanges }
    cancel()
    await permissionsStore.updateRolePermissions(organizationStore.selected, selectedRole, changes)
    await load()
  }

  const determineToggles = (systemPermissions, orgIncludes, rolePermissions, adds, removes) => {
    console.log('rolePermissions')
    console.log(rolePermissions)
    const permissionlistCopy = _.cloneDeep(systemPermissions)
    const orgPermissionsArray = []
    const rolePermissionsArray = []
    permissionlistCopy.forEach(p => {
      const name = p.permission
      p.initial = p.enabled = false

      // Lock permissions if set at org level
      if (orgIncludes.includes(name)){
        console.log('disabling')
        console.log(name)
        p.initial = p.enabled = true
        p.disabled = true
        orgPermissionsArray.push(p)
        return
      }

      if (rolePermissions.includes(name)) {
        p.initial = p.enabled = true
      }

      if (adds.includes(name)) p.enabled = true
      if (removes.includes(name)) p.enabled = false
      rolePermissionsArray.push(p)
      return
    })
    // Sort returned array so permissions set by org are grouped at the top
    return [...orgPermissionsArray, ...rolePermissionsArray]
  }

  useEffect( () => {
    const permissionsForOrg = determineToggles(
      permissionsStore.systemPermissions, 
      permissionsStore.includesPermissions, 
      permissions,
      addChanges,
      removeChanges
    )
    setPermissionsWithChanges(permissionsForOrg)
  }, [ 
    permissionsStore.systemPermissions, 
    permissionsStore.includesPermissions, 
    permissions,
    addChanges,
    removeChanges
  ])

  const prepChange = (name, value, initialValue) => {
    console.log("Updating Changes")
    console.log(initialValue)
    console.log(name)
    console.log(value)
    const removeItem = (array, value) => {
      const clonedArray = _.cloneDeep(array)
      const index = clonedArray.indexOf(value)
      if (index > -1) {
        clonedArray.splice(index, 1)
      }
      return clonedArray
    }
    if (value) {
      if (removeChanges.includes(name)){
        const updatedRemove = removeItem(removeChanges, name)
        setRemoveChanges(updatedRemove)
      }
      if (!addChanges.includes(name) && value !== initialValue){
        setAddChanges([...addChanges, name])
      }
    } else {
      if (addChanges.includes(name)){
        const updatedAdd = removeItem(addChanges, name)
        setAddChanges(updatedAdd)
      }
      if (!removeChanges.includes(name) && value !== initialValue){
        setRemoveChanges([...removeChanges, name])
      }
    }
  }

  return (
    <div className="permissions-modal">
      <div className="title" > Role Permissions </div> 
      <div className="role-selector">
        <PermissionsDropDown 
          items={allRolesForOrg}
          setSelectedItem={setSelectedRole}
          selectedItem={selectedRole}
          setNewRole={setNewRole}
        />
      </div>
      <div className="list-container">
        <PermissionListWithToggles
          loading={loading}
          items={permissionswithChanges}
          toggle={prepChange}
        />
      <CancelAndSaveButtons 
        disabled={
          addChanges.length === 0 && 
          removeChanges.length === 0 &&
          !newRole
        } 
        cancel={cancel} 
        save={save}
        label={newRole ? 'CREATE' : null}
      />
      </div>
    </div>
  )
})

export default RolePermission
