import React, { useState } from "react"
import { TextField } from "@material-ui/core"
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const ITEM_HEIGHT = 48
const CREATE_NEW_ROLE_TEXT = 'Create new role: '
const filter = createFilterOptions();

const PermissionsDropDown = ({items, setSelectedItem, selectedItem, setNewRole}) => {

  const handleChange = (event, newValue) => {
    // If the user selects the new value they created, keep just the value
    let selected = newValue
    if (newValue.includes(CREATE_NEW_ROLE_TEXT)) {
      selected = newValue.slice(17).toUpperCase()
      setNewRole(true)
    } else {
      setNewRole(false)
    }

    setSelectedItem(selected)
  }

  const handleFilter = (items, params) => {
    const filtered = filter(items, params);
     // Suggest the creation of a new value as use types
     if (params.inputValue !== '') {
      filtered.push(`${CREATE_NEW_ROLE_TEXT}${params.inputValue}`);
    }

    return filtered;
  }

  return (
    <div>
      <Autocomplete
        value={selectedItem}
        onChange={handleChange}
        filterOptions={handleFilter}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="select-org-role"
        options={items}
        renderOption={(option) => option}
        style={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label="Search for a role" />
      )}
    />
    </div>
  )
}

export default PermissionsDropDown