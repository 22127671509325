import { isArray } from "lodash"
import { Requests } from "./Requests"

export class OrganizationAPI {

  /**
   * Fetch all organizations
   * @returns {Array<Object>}
   */
  static async fetchAllOrganizations(endpoint){
    const url = `${endpoint}/api/organization`
    const organizations = await Requests.authRequest(url, 'GET')
    return (organizations === undefined || !isArray(organizations)) ? [] : organizations
  }

  /**
   * Fetch an organization by id
   * @param {Number|String} id
   * @returns {Object}
   */
  static async fetchOrganization(endpoint, id){
    const url = `${endpoint}/api/organization/${id}`
    return Requests.authRequest(url, "GET")
  }

  /**
   * Create an organization
   * @param {String} name
   * @returns {Object}
   */
  static async createOrganization(endpoint, name){
    const url = `${endpoint}/api/organization`
    const payload = { "organizationName": name }
    return Requests.authRequest(url, "POST", payload)
  }
}