import { OktaAuth } from '@okta/okta-auth-js'
import { oktaAuthConfig } from '../okta_config'
const authClient = new OktaAuth(oktaAuthConfig)

export class Requests {

  static async request({url, method, payload, headers, parse}){
    let options
    if (payload !== null){
      options = {
        method,
        headers,
        body: JSON.stringify(payload)
      }
    } else {
      options =  {
        method,
        headers
      }
    }
    try {
      const res = await fetch(url, options)
      return parse ? await res.json() : res
    } catch (error) {
      console.error(`ERROR: Unable to fetch from endpoint ${url}\nError Message: ${error}`)
      return
    }
  }

  static async basicRequest(url, method, payload, headers){ 
    return await this.request({url, method, payload, headers, parse: true})
  }

  static async jsonRequest(url, method, payload){
    const headers = {
      'content-type': 'application/json'
    }
    return await this.request({url, method, payload, headers, parse: true})
  }

  static async authRequest(url, method, payload){
    const accessTokenInfo = await authClient.tokenManager.get('accessToken')
    const accessToken = accessTokenInfo.accessToken
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    }
    return await this.request({url, method, payload, headers, parse: true})
  }

}
