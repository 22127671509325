import React, { useState, useEffect } from 'react'
import { observer } from "mobx-react"
import _ from 'lodash'
import { Button } from '@material-ui/core'
import { useStores } from '../../hooks/useStores'
import PermissionList from '../reuseable/PermissionList'
import PermissionsHelper from '../../utilities/PermissionsHelper'
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    borderRadius: '4px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    height: '175px',
    width: '50%',
    margin: '32px 16px',
  },
}));

const CREATE = 'CREATE'
const UPDATE = 'UPDATE'

const RolePermission = observer(() => {

  const classes = useStyles()
  const [ open, setOpen ] = React.useState(false)
  const [ formType, setFormType ] = useState(CREATE)
  const [ formPermission , setFormPermission ] = useState('')
  const [ formDescription , setFormDescription ] = useState('')
  const [ filter , setFilter ] = useState('')
  const handleOpen = (type) => {
    type ? setFormType(type) : setFormType(CREATE)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const load = async () => {
    setLoading(true)
    await permissionsStore.getSystemPermissions()
    setLoading(false)
  }

  const handleSubmit = async (e) => {
    handleClose()
    setLoading(true)
    await permissionsStore.createOrUpdateSystemPermission(formPermission, formDescription)
    await load()
    setFormPermission('')
    setFormDescription('')
    e.preventDefault()
  }

  const edit = (name, description) => {
    if (!name) name=''
    if (!description) description=''
    setFormPermission(name)
    setFormDescription(description)
    handleOpen(UPDATE)
  }

  const add = () => {
    setFormPermission('')
    setFormDescription('')
    handleOpen(CREATE)
  }

  const { permissionsStore } = useStores()

  const [ loading, setLoading ] = useState(false)

  useEffect(async () => {
    await load()
  }, [])

  return (
    <div className="permissions-modal">
      <div className="title" >System Permissions</div> 
      <div className="filter-items"><TextField label="Search permissions" value={filter} onInput={(e) => {  setFilter(e.target.value)}}/></div>
      <PermissionList
        loading={loading}
        items={PermissionsHelper.filterPermissions(permissionsStore.systemPermissions, filter)}
        edit={edit}
      />
      <Button
        variant="contained"
        color="default"
        size="large"
        className="save-button"
        disabled={loading}
        onClick={add}
      >
        Add
      </Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <TextField label="Permission String" value={formPermission} onInput={(e) => { console.log(e.target.value); setFormPermission(e.target.value)}}/>
            <br/>
            <TextField label="Description" value={formDescription}  onInput={(e) => setFormDescription(e.target.value)}/>
            <br/>
            <div className='button-container'>
              <Button
                variant="contained"
                color="default"
                size="large"
                className="modal-button"
                disabled={formPermission.length === 0 || formDescription.length === 0}
                onClick={handleSubmit}
              >
                {formType}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
})

export default RolePermission
