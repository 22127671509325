import { isArray } from "lodash"
import { Requests } from "./Requests"
import endpoints from '../../endpoints'

const PERMISSIONS_BASE_URL = endpoints.MOBILE_APP_API


export default class PermissionsHelper {

  static async getOrgPermissions(org){
    let encodedOrg = encodeURIComponent(org)
    const url = `${PERMISSIONS_BASE_URL}/orgs/${encodedOrg}/permissions`
    let res = await Requests.authRequest(url, 'GET')
    return res
  }

  static async updateOrgPermissions(org, permissions){
    let encodedOrg = encodeURIComponent(org)
    const url = `${PERMISSIONS_BASE_URL}/orgs/${encodedOrg}/permissions`
    await Requests.authRequest(url, 'PATCH', permissions)
  }

  static async getRolePermissions(org){
    const encodedOrg = encodeURIComponent(org)
    const url = `${PERMISSIONS_BASE_URL}/orgs/${encodedOrg}/roles/permissions`
    let res = await Requests.authRequest(url, 'GET')
    if (!res || !isArray(res)) return []
    const rolePermissions = res.map( roleObj => {
      return {
        role: roleObj.groupName,
        permissions: roleObj.groupPermissionList,
        orgName: roleObj.organizationId
      }
    })
    return {rolePermissions}
  }

  static async updateRolePermissions(org, role, permissions){
    const encodedOrg = encodeURIComponent(org)
    const encodedRole = encodeURIComponent(role)
    const url = `${PERMISSIONS_BASE_URL}/orgs/${encodedOrg}/roles/${encodedRole}/permissions`
    await Requests.authRequest(url, 'POST', permissions)
  }

  static async getUserPermissions(org, userId){
    const encodedOrg = encodeURIComponent(org)
    const url = `${PERMISSIONS_BASE_URL}/orgs/${encodedOrg}/users/${userId}/permissions`
    let res = await Requests.authRequest(url, 'GET')
    if (!res || !isArray(res)) return []
    return res
  }

  static async getSystemPermissions(){
    const url = `${PERMISSIONS_BASE_URL}/system-permissions`
    let res = await Requests.authRequest(url, 'GET')
    if (!res || !isArray(res)) return []
    return res
  }

  static async createOrUpdateSystemPermission(permission){
    const url = `${PERMISSIONS_BASE_URL}/system-permissions`
    let res = await Requests.authRequest(url, 'POST', permission)
    console.log('created permission')
    console.log(res)
    if (!res || !isArray(res)) return []
    return res
  }
  static sortItems = (order, property) => (a, b) => {
    const itemA = _.get(a, property)
    const itemB = _.get(b, property)
    if(order === 'asc'){
      return itemA < itemB ? -1 : 1
    } else {
      return itemA < itemB ? 1 : -1
    }
  }
  static filterPermissions = (Items, filter) => {
    filter = filter.toLowerCase();
    if(!filter) return Items
    if(Items && Items.length > 0){
      const filteredItems = Items.filter((item) => item.description?.toLowerCase().includes(filter) || item.permission?.toLowerCase().includes(filter))
      return filteredItems
    }
  }
  
}