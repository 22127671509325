import React, { useEffect } from 'react'
import { observer } from "mobx-react"
import { useStores } from '../hooks/useStores'

const Home = observer(() => {

  const { homeStore } = useStores()

  useEffect(async () => {
    await homeStore.getJoke()
  }, [])

  const joke = _.get(homeStore, 'joke')
  return (
    <div className='home'>
      <div className='joke-container'>
        <div className='joke'>
          {joke ? joke : null}
        </div>
      </div>
    </div>
  )
})

export default Home