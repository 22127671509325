import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { Paper, List, ListItem, ListItemText, Button, InputBase } from '@material-ui/core'
import { useStores } from '../../hooks/useStores'
import { useKey } from '../../hooks/useKey'
import CircularProgress from '@material-ui/core/CircularProgress'

const UserPermissionsSearch = observer(() => {

  const [ searchedEmail, setSearchedEmail ] = useState('')
  const { permissionsStore } = useStores()
  const [ error, setError ] = useState('')
  const [ loading, setLoading ] = useState('')

  const { userPermissions, getUserInfo, setUserPermissions} = permissionsStore

  const handleInputChange = e => {
    setError('')
    let email = e.target.value
    setSearchedEmail(email)
  }

  const handleSearch = async e => {
    e.preventDefault()
    setUserPermissions([])
    if (!searchedEmail) return
    setLoading(true)
    setError('')
    await getUserInfo(searchedEmail)
    setLoading(false)
  }

  useKey("Enter", handleSearch)

  return (
    <div className="permissions-modal">
       <h2 className="user-search-title">User Check</h2>
       <div className="search-box">
        <div className="search-header">
          <Paper component="form" className="search-bar">
            <InputBase
                className="input"
                autoFocus
                placeholder="Search User's Email"
                onChange={handleInputChange}
            />              
          </Paper>
          <Button variant="contained" className="search-button" aria-label="directions" onClick={handleSearch}>
            Test
          </Button>
        </div>
        <br/>
        {
          loading && userPermissions.length === 0 ? 
          <div className="loading-group"> 
            <CircularProgress disableShrink className="loading"/> 
          </div> :
          <div className='display-group'>
            <br/>
            <div className='roles'>{`Roles: ${permissionsStore.userRoles}`}</div>
            <br/>
            <div className='permissions'>Permissions:</div>
            <br/>
            <div className="search-list">
              <List >
                {userPermissions.map(p =>
                  <ListItem 
                    key={p}
                  >
                    <ListItemText
                      primary={p}
                    />
                  </ListItem>
                )}
              </List>
            </div>
          </div>
        }
       </div>
      <div className="error-message">
        { error.length > 0 ? <div className="error"> <p>{error}</p><p><strong>&nbsp;{searchedEmail}</strong></p> </div> : null}
      </div>
    </div>
  )
})

export default UserPermissionsSearch