import React from 'react'
import { Grid, List, ListItem, ListItemSecondaryAction } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Toggle } from './Toggle'
import PermissionsHelper from '../../utilities/PermissionsHelper'

const PermissionListWithToggles = ({ loading, items=[], toggle=()=>{} }) => {

  return (
    <div className="list-container">
    { 
      loading ? 
      <div className="permissions-list">
        <Grid item xs={12} md={6} className="list"  >
          <div className="loading-group"> 
              <CircularProgress disableShrink className="loading"/> 
          </div> 
        </Grid>
      </div>
      :
      <div className="permissions-list">
        <Grid item xs={12} md={6} className="list"  >
          <List >
          {items.sort(PermissionsHelper.sortItems('asc', 'permission')).map( p =>
              <ListItem 
                className={'item'}
                key={p.permission}
              >
                <div className='item-text'>
                  <div className='permission'> {p.permission} </div>
                  <div className='description'> {(p.description === '' || !p.description) ? 'No description' : p.description} </div>
                </div>
                <ListItemSecondaryAction className='right'> 
                { !p.disabled ? null : <div className='locked'> {'Set at org level'} </div>}
                <Toggle value={p.enabled} toggle={value => toggle(p.permission, value, p.initial)} disabled={p.disabled}/>
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </Grid>
      </div>
    }
  </div>
  )
}

export default PermissionListWithToggles