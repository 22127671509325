import React, { Component } from "react"
import ReactDOM from "react-dom"
import { Provider } from "mobx-react"
import { BrowserRouter as Router } from "react-router-dom"

import OktaRouter from "./views/OktaRouter"
import HomeStore from './stores/Home'
import TemplatesStore from "./stores/Templates"
import PermissionsStore from "./stores/Permissions" 
import OrganizationStore from './stores/Organizations'
import FeatureFlagStore from './stores/FeatureFlags'

import "./styles/RootStyles.scss"

class Application extends Component {
  render() {


    return (
      <Provider 
        homeStore={new HomeStore()}
        templatesStore={new TemplatesStore()} 
        permissionsStore={new PermissionsStore()}
        organizationStore={new OrganizationStore()}
        featureFlagsStore={new FeatureFlagStore()}
        className="provider"
      >
        <Router className='router'>
          <OktaRouter/>
        </Router>
      </Provider>
    )
  }
}
ReactDOM.render(<Application className="application"/>, document.getElementById("application"))