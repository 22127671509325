import { makeAutoObservable } from "mobx"
import { TemplatesHelper } from "../utilities/TemplatesHelper"
import _ from "lodash"

export default class Templates {

  defaultTemplates = []
  orgTemplates = []
  keys = []
  loading = false
  
  options = {
    categories: new Set(),
    workflows: {},
    tokens: {}
  }
  selected = {
    category: "None",
    workflow: "None",
    token: "None"
  }

  constructor(){
    makeAutoObservable(this)
  }

  get sortedCategories(){
    let categories = _.uniq([...this.options.categories])
    categories.sort()
    categories.unshift("None")
    return categories
  }

  get sortedWorkflows(){
    let workflows = []
    const categories = this.sortedCategories
    if (this.selected.category === "None"){ // Fill with all workflows
      for (const index in categories){
        const category = categories[index]
        if (category === "None") continue
        if(this.options.workflows[category])
          workflows = workflows.concat([...this.options.workflows[category]])
      }
    } else { 
      workflows = [...this.options.workflows[this.selected.category]]
    }
    workflows.sort()
    workflows = _.uniq(workflows)
    workflows.unshift("other")
    workflows.unshift("None")
    return workflows
  }
  
  /**
   * This function is a bit wild and could be cleaned up at some point. It works for now tho
   */
  get sortedTokens(){
    let tokens = []
    if(this.selected.workflow === "None" && this.selected.category === "None"){ // Load all the tokens
      if (Object.keys(this.options.tokens).length !== 0){
        for (const workflow in this.options.tokens){
          if (workflow === "other"){
            if (Object.keys(this.options.tokens.other).length !== 0){
              for (const category in this.options.tokens.other){
                if(this.options.tokens.other[category])
                  tokens = tokens.concat([...this.options.tokens.other[category]])
              }
            }
          } else {
            if (this.options.tokens[workflow])
              tokens = tokens.concat([...this.options.tokens[workflow]])
          }
        }
      }
    } else if (this.selected.workflow === "None" && this.selected.category !== "None") { // just a category is selected
      // Get list of workflows for given category
      const workflows = this.sortedWorkflows // sortedWorkflows returns an array, not a set, so 'for' loop uses indexes
      for (const index in workflows){
        const workflow = workflows[index]
        if (workflow === "None")
          continue
        if (workflow === "other") {
          const otherTokens = this.options.tokens.other
          if (otherTokens && otherTokens[this.selected.category])
            tokens = tokens.concat([...otherTokens[this.selected.category]])
        } else {
          if (this.options.tokens[workflow])
            tokens = tokens.concat([...this.options.tokens[workflow]])
        }
      }   
    } else { // just a workflow is selected (and possibly a category. only need to check category when selecting other)
      if (this.selected.workflow === "other"){
        const otherTokens = this.options.tokens.other
        if(this.selected.category === "None"){
          for (const category in otherTokens ){
            if(otherTokens[category])
              tokens = tokens.concat([...otherTokens[category]])
          }
        } else { // Need to get specific 'other' tokens for the selected category
          tokens = tokens.concat([...otherTokens[this.selected.category]])
        }
      } else { // any other workflow selected (don't have to worry about none since the outer scope of 'if' statements)
        if (this.options.tokens[this.selected.workflow])
          tokens = tokens.concat([...this.options.tokens[this.selected.workflow]])
      }
    }
    tokens = _.uniq(tokens)
    tokens.sort()
    tokens.unshift("None")
    return tokens
  }

  setSelectedCategory = selected => {
    this.selected.workflow = "None"
    this.selected.token = "None"
    this.selected.category = selected
  }
  
  setSelectedWorkflow = selected => {
    this.selected.token = "None"
    this.selected.workflow = selected
  }

  setSelectedToken = selected => {
    this.selected.token = selected
  }

  /**
   * Parses through the default templates and gathers the unique categories and tokens.
   * It is expected that if a category or token is present in a non-default template,
   * there will be a default template with that category or token.
   * @modifies this.options
   */
  setDropdowns = () => {
    console.log("setting dropdowns")
    const defaultTemplates = this.defaultTemplates
    for (const id in defaultTemplates){ // loop over the templates to set the workflows and tokens
      const template = defaultTemplates[id]
      this.options.categories.add(template.category[0])
      const split = template.token.split('.', 3) // Start parsing the token 
      if (this.options.workflows[template.category] === undefined) // Setting workflow
          this.options.workflows[template.category] = new Set()
      if (split.length > 1) {
        const workflow = split[0]
        const token = split[1]
        this.options.workflows[template.category].add(workflow)
        if (this.options.tokens[workflow] === undefined)          
          this.options.tokens[workflow] = new Set()
        this.options.tokens[workflow].add(token) 
      } else {  // The token is only length 1 and thus a 'other' workflow
        if (this.options.tokens.other === undefined) // Initialize a new set within tokens.other
          this.options.tokens.other = {}
        if(this.options.tokens.other[template.category] === undefined)
          this.options.tokens.other[template.category] = new Set()
        this.options.tokens.other[template.category].add(split[0])
      }
    }
  }

  /**
   * Resets a page back to an empty store
   * This is used to wipe all dropdowns and templates when selecting a new page
   * @modifies this.keys
   * @modifies this.templates
   * @modifies this.options
   */
  resetPage(){
    this.keys = []
    this.templates = []
    this.defaultTemplates = []
    this.options = {
      categories: new Set(),
      workflows: {},
      tokens: {}
    }
    this.selected = {
      category: "None",
      workflow: "None",
      token: "None"
    }
  }

  get templatesToRender(){
    const templatesToRender = TemplatesHelper.generateTemplatetoRender(this.orgTemplates, this.defaultTemplates)
    const templates = TemplatesHelper.filter(templatesToRender, this.selected.category, this.selected.workflow, this.selected.token)
    templates.sort((a,b) => a.token < b.token ? -1 : 1)
    return templates
  }

  getDefaultTemplates = async () => {
    console.log('Getting Default Templates')
    this.defaultTemplates = await TemplatesHelper.getTemplates('DEFAULT')
    this.setDropdowns()
  }

  getTemplates = async organization => {
    console.log("fetching templates")
    if (!organization) return
    this.loading = true
    if (this.defaultTemplates.length === 0) {
      await this.getDefaultTemplates()
    }
    if (organization === 'DEFAULT') {
      this.orgTemplates = []
      this.loading = false
      return
    }
    this.orgTemplates = await TemplatesHelper.getTemplates(organization)
    this.loading = false
  }

  async saveTemplate(id, org, type, category, text, token, selectedOrg){

		if (org === "DEFAULT" && selectedOrg !== "DEFAULT"){
      await TemplatesHelper.copy(type, selectedOrg, category, text, token)
		} else {
			await TemplatesHelper.update(id, text)
    }
    await TemplatesHelper.getTemplates(selectedOrg)
  }

  async deleteTemplate(id){
    await TemplatesHelper.delete(id)
  }  

  setUp = async (org) => {
    this.resetPage()
    this.keys = await TemplatesHelper.getKeys()
    await this.getTemplates(org)
  }
}
