import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { observer } from "mobx-react"
import { useStores } from '../../hooks/useStores'
import PermissionListWithToggles from '../reuseable/PermissionListWithToggles'
import { CancelAndSaveButtons } from '../reuseable/CancelAndSaveButtons'
import PermissionsHelper from '../../utilities/PermissionsHelper'
import TextField from '@material-ui/core/TextField';
const OrgPermissions = observer(() => {
  const { permissionsStore, organizationStore } = useStores()

  const [ addChanges, setAddChanges ] = useState([])
  const [ removeChanges, setRemoveChanges ] = useState([])
  const [ permissions, setPermissions ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ filter , setFilter ] = useState('')

  const load = async () => {
    setLoading(true)
    if (permissionsStore.systemPermissions.length === 0) {
      await permissionsStore.getSystemPermissions()
    }
    await permissionsStore.getOrgPermissions(organizationStore.selected) 
    setLoading(false)
  }

  const cancel = () => {
    setAddChanges([])
    setRemoveChanges([])
  }

  useEffect(async () => {
    cancel()
    await load()
  }, [organizationStore.selected])
  
  const save = async () => {
    const changes = { addChanges, removeChanges }
    cancel()
    setLoading(true)
    await permissionsStore.updateOrgPermissions(organizationStore.selected, changes)
    await load()
  }

  const prepChange = (name, value, initialValue) => {
    const removeItem = (array, value) => {
      const clonedArray = _.cloneDeep(array)
      const index = clonedArray.indexOf(value)
      if (index > -1) {
        clonedArray.splice(index, 1)
      }
      return clonedArray
    }
    if (value) {
      if (removeChanges.includes(name)){
        const updatedRemove = removeItem(removeChanges, name)
        setRemoveChanges(updatedRemove)
      }
      if (!addChanges.includes(name) && value !== initialValue){
        setAddChanges([...addChanges, name])
      }
    } else {
      if (addChanges.includes(name)){
        const updatedAdd = removeItem(addChanges, name)
        setAddChanges(updatedAdd)
      }
      if (!removeChanges.includes(name) && value !== initialValue){
        setRemoveChanges([...removeChanges, name])
      }
    }
  }

  const determineToggles = (permissions, enabled, disabled) => {
    const permissionlistCopy = _.cloneDeep(permissions)
    const toggles = permissionlistCopy.map(p => {
      const name = p.permission
      p.initial = p.enabled = false
      if (enabled.includes(name) && !disabled.includes(name)) {
        p.initial = p.enabled = true
      }
      if (addChanges.includes(name)) p.enabled = true
      if (removeChanges.includes(name)) p.enabled = false
      return p
    })    
    console.log(JSON.stringify(toggles))
    return toggles
  }

  useEffect( () => {
    const permissionsForOrg = determineToggles(
      permissionsStore.systemPermissions, 
      permissionsStore.includesPermissions, 
      permissionsStore.excludesPermissions
    )
    setPermissions(permissionsForOrg)
  }, [ 
    permissionsStore.systemPermissions, 
    permissionsStore.includesPermissions, 
    permissionsStore.excludesPermissions,
    addChanges,
    removeChanges
  ])
  
  return (
    <div className="permissions-modal">
      <div className="title" >Organization Permissions</div> 
      <div className="filter-items"><TextField label="Search permissions" value={filter} onInput={(e) => {  setFilter(e.target.value)}}/></div>
      <div className="list-container">
        <PermissionListWithToggles
          loading={loading}
          items={PermissionsHelper.filterPermissions(permissions, filter)}
          toggle={prepChange}
        />
        <CancelAndSaveButtons 
          disabled={
            addChanges.length === 0 && 
            removeChanges.length === 0 
          } 
          cancel={cancel} 
          save={save}
        />
      </div>
    </div>
  )
})

export default OrgPermissions
