import React from 'react'
import NavigationBar from './NavigationBar' 

export const withNavBar = WrappedComponent => {
  class withNavBar extends React.Component {
    render() {
      return (
        <div className="withNav">
          <NavigationBar/>
          <WrappedComponent/>
        </div>
      )
    }
  }
  return withNavBar
}
 