import { Requests } from "./Requests"
import endpoints from '../../endpoints'

const WEB_APP_URL = endpoints.WEB_APP

export default class UserHelpers {

  /**
   * User by Email
   * @returns {Object}
   */
  static async getByEmail(email){
    if (!email) {
      console.error("No email provided to search on")
      return
    }
    const url = `${WEB_APP_URL}/api/user/email/${email}`
    const user = await Requests.authRequest(url, 'GET')
    return user
  }

}