import { useEffect, useRef } from 'react'

export const useKey = (key, cb) => {
    const callBackRef = useRef(cb)
    useEffect(() => {
      callBackRef.current = cb
    })
    useEffect(()=> {
      const handle = (e) => {
        if (e.code === key){
          callBackRef.current(e)
        }
      } 
      document.addEventListener("keypress", handle)
      return () => document.removeEventListener("keypress", handle)
    }, [key])
  }  