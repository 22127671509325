import { makeAutoObservable } from "mobx"
import PermissionsHelper from '../utilities/PermissionsHelper'
import UserHelper from '../utilities/UserHelper'
import _ from 'lodash'
export default class Permissions {
  
  title = 'Permissions'
  includesPermissions = []
  excludesPermissions = []
  rolePermissions = []
  userPermissions = []
  userRoles = []
  systemPermissions = []
  loggedInUserPermissions = []
  loggedInUserFetched = false
  user = {}

  constructor(){
    makeAutoObservable(this)
  }  

  setIncludes = permissions => {
    this.includesPermissions = permissions
  }

  setExcludes = permissions => {
    this.excludesPermissions = permissions
  }

  getOrgPermissions = async (org) => {
    console.log(org)
    const fetchedOrgPermissions = await PermissionsHelper.getOrgPermissions(org)
    console.log("here")
    console.log(fetchedOrgPermissions)
    if (!fetchedOrgPermissions){
      console.error('Issue fetching org permissions')
      return
    }
    this.setIncludes(fetchedOrgPermissions.includePerms || [])
    this.setExcludes(fetchedOrgPermissions.excludePerms || [])
  }

  updateOrgPermissions = async (org, changes) => {
    // Add changes will be added to includes and remove from exludes
    const newIncludes = _.difference(_.union(this.includesPermissions || [], changes.addChanges), changes.removeChanges)
    // Remove changes will only remove from includes. This prevents the case were we may enable USER_MANAGEMENT_TAB, then remove it
    // at the org level. If we want to then enable it for ADMINs only, this org level excludes would prevent that functionality
    const newExcludes = _.difference(this.excludesPermissions || [], changes.addChanges)
    console.log("New Includes")
    console.log(newIncludes)
    console.log("New Excludes")
    console.log(newExcludes)
    await PermissionsHelper.updateOrgPermissions(org, { includes: newIncludes, excludes: newExcludes })
  }

  setRolePermissions = permissions => {
    this.rolePermissions = permissions
  }

  getRolePermissions = async organization => {
    const orgAndRolepermissions = await PermissionsHelper.getRolePermissions(organization)
    this.setRolePermissions(orgAndRolepermissions.rolePermissions)
  }

  updateRolePermissions = async (org, role, permissions) => {
    console.log('updating role permissions', permissions)
    await PermissionsHelper.updateRolePermissions(org, role, permissions)
  }

  setUserPermissions = permissions => {
    this.userPermissions = permissions
  }

  setUserRoles = roles => {
    this.userRoles = roles
  }

  getUserInfo = async email => {
    const user = await UserHelper.getByEmail(email)
    if (!user) return
    console.log(user)

    const org = user.organization.name
    const userId = user._id
    const fetchedUserPermissions = await PermissionsHelper.getUserPermissions(org, userId)
    this.setUserPermissions(fetchedUserPermissions)
    this.setUserRoles(user.roles)
  }

  getLoggedInUsersPermissions = async email => {
    const user = await UserHelper.getByEmail(email)
    if (!user) return

    const org = user.organization.name
    const userId = user._id
    const fetchedUserPermissions = await PermissionsHelper.getUserPermissions(org, userId)
    this.loggedInUserPermissions = fetchedUserPermissions
    this.loggedInUserFetched = true
    this.user = user
  }


  getSystemPermissions = async () => {
    const fetchedSystemPermissions = await PermissionsHelper.getSystemPermissions()
    this.setSystemPermissions(fetchedSystemPermissions)
  }

  setSystemPermissions = permissions => {
    this.systemPermissions = permissions
  }

  createOrUpdateSystemPermission = async (permission, description) => {
    await PermissionsHelper.createOrUpdateSystemPermission({ permission, description})
  }
}