import endpoints from '../endpoints' 

const oktaAuthConfig = {
  issuer: endpoints.OKTA_ISSUER,
  clientId: endpoints.EC_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email'],
  pkce: true,

}

const oktaSignInConfig = {
  baseUrl: endpoints.OKTA_BASE_URL,
  clientId: endpoints.EC_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
  authParams: {
    // If your app is configured to use the Implicit flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to uncomment the below line
    pkce: true
  }
  // Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
}

export { oktaAuthConfig, oktaSignInConfig }