import React from "react"
import { Button } from '@material-ui/core'

export const CancelAndSaveButtons = props => (
  <div className="permission-buttons">
    <Button
      variant="contained"
      color="default"
      size="large"
      className="cancel-button"
      disabled={props.disabled}
      onClick={props.cancel}
    >
      Cancel
    </Button>
    <Button
      variant="contained"
      color="default"
      size="large"
      className="save-button"
      disabled={props.disabled}
      onClick={props.save}
    >
      {props.label ? props.label: 'SAVE'}
  </Button>
</div>
)