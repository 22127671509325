import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"
import DropdownMenu from "../components/reuseable/DropdownMenu"
import TemplateList from "../components/nonreuseable/TemplateList"
import { CircularProgress } from "@material-ui/core"
import Button from '@mui/material/Button'
import DownloadIcon from '@mui/icons-material/Download'
import { Error } from "../components/reuseable/Error"
import { useStores } from '../hooks/useStores'

import { CSVLink } from "react-csv"

const Templates = observer(() => {

  const { templatesStore, organizationStore } = useStores()
  const [ templatesForCSV, setTemplatesForCSV ] = useState([])

  useEffect(async () => {
    await templatesStore.setUp()
  }, [])

  useEffect(async () => {
    await templatesStore.resetPage()
    await templatesStore.getTemplates(organizationStore.selected)
  }, [organizationStore.selected])

  useEffect(() => {
    const formattedTemplates = templatesStore.templatesToRender.map( template => ({
      category: template.category,
      token: template.token,
      text: template.text,
      customized: template.key !== 'DEFAULT' ? 'true' : 'false'
    }))
    formattedTemplates.sort((a, b) => (a.category > b.category) ? 1 : -1)
    setTemplatesForCSV(formattedTemplates)
  }, [templatesStore.templatesToRender])

  return (
    <div className="scripts_container">
      <div className="buttons">
        <div className="button_grid">
          <DropdownMenu 
            type="Category"
            selected={templatesStore.selected.category}
            items={templatesStore.sortedCategories}
            setSelected={templatesStore.setSelectedCategory}
          />
          <DropdownMenu 
            type="Workflow"
            selected={templatesStore.selected.workflow}
            items={templatesStore.sortedWorkflows}
            setSelected={templatesStore.setSelectedWorkflow}
          />
          <DropdownMenu 
            type="Token"
            selected={templatesStore.selected.token}
            items={templatesStore.sortedTokens}
            setSelected={templatesStore.setSelectedToken}
          />
        </div>
        <div className="csv-download">
          <CSVLink 
            data={templatesForCSV}
            filename={`${organizationStore.selected.split(" ").join("")}-Templates.csv`}
            className="btn btn-primary"
            target="_blank"
          > 
            <Button variant="contained">
              <DownloadIcon/>
            </Button>
          </CSVLink>
        </div>
      </div>
      {
        templatesStore.loading ? <div className='loading-container'><CircularProgress/></div> : 
        organizationStore.initialFetch && organizationStore.list === 0 ? Error('Failed to load organizations') :
          <div className="template_list">
            <TemplateList 
              templates={templatesStore.templatesToRender}
              reload={() => templatesStore.getTemplates(organizationStore.selected)}
            />
          </div>
      }
    </div>
  )
})

export default Templates
