import React, { useState, useRef } from "react"
import { 
  Button, 
  ClickAwayListener, 
  Grow, 
  Paper, 
  Popper, 
  MenuItem, 
  MenuList,
  TextField
} from "@material-ui/core"


/**
 * A dropdown menu that is separated from the MobX state
 */
const DropdownMenu = ({ 
  selected, type, icon, setSelected, items
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  // const [isOpen, setOpen] = useState(false)
  const [filter, setFilter] = useState()

  const handleToggle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
    // setOpen(!isOpen)
  }

  const handleSelection = (event, name) => {
    if (name) setSelected(name)
    if (event && anchorEl.contains(event.target))
      return
    setAnchorEl(null)
    setFilter(null)
  }

  const handleFilter = (e) => {
    setFilter(e.target.value)
  }

  const isOpen = Boolean(anchorEl);

  let filledText = selected ? selected : "Select Org"
  if (type) filledText = `${type}: ${filledText}` 
  return (
    <div className="dropdown_menu">
      <Button 
        aria-owns={isOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {<div className='label'>{filledText}</div>}
        {icon ? <img src={icon}/> : null}
      </Button>
      <Popper open={isOpen} anchorEl={anchorEl} transition disablePortal placement={'bottom-end'} style={{ width: 400, zIndex: '1' }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper className="menu">
              <ClickAwayListener onClickAway={(e) => handleSelection(e)}>              
                <MenuList>
                  <MenuItem
                    style={{ backgroundColor: 'transparent' }}
                    onKeyDown={(e) => e.stopPropagation()}
                  >
                      <TextField size="small" autoFocus onChange={handleFilter} label="Org Filter" variant="outlined" fullWidth />
                  </MenuItem>
                  {items.filter((i) => filter ? i?.toLowerCase().indexOf(filter.toLowerCase()) > -1 : true).map((name,i) => (
                    <MenuItem key={i} onKeyDown={(e) => e.stopPropagation()}
                      onClick={(e) => handleSelection(e, name)}> 
                      {name} 
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default DropdownMenu
