import React, { useState, Component } from "react"
import { observer } from "mobx-react"
import { Button, Tooltip, Modal } from "@material-ui/core"
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete"
import "@webscopeio/react-textarea-autocomplete/style.css"
import resetImg from "../../assets/reset.png"
import saveImg from "../../assets/save.png"
import editImg from "../../assets/edit.png"
import { useStores } from '../../hooks/useStores'

const Loading = () => <div>Loading</div>
const Item = ({entity}) => <div>{`${entity}`}</div>

const Template = observer((props) => {

  const [text, setText] = useState(props.template.text || "")
  const [disabled, setDisabled] = useState(true)
  const [open, setOpen] = useState(false)
  const [defaultOpen, setDefaultOpen] = useState(false)

  const color = props.template.color
  const category = props.template.category
  const token = props.template.token
  const key = props.template.id
  const organization = props.template.key
  const type = props.template.keyType

  const { templatesStore, organizationStore } = useStores()

	const openResetModal = () => {
		organization !== "DEFAULT" ? setOpen(true) : setDefaultOpen(true)
	}
	
	const save = async () => {
		setDisabled(true)
		await templatesStore.saveTemplate(key, organization, type, 
			category, text, token, organizationStore.selected)
    props.reload()
	}

	const resetToDefault = async () => {
		await templatesStore.deleteTemplate(key)
    props.reload()
	}

  return (
    <div className="template" style={{backgroundColor: color}}>
      <div className="template-container">
        <div className='template-header'>
          <h4> {organization.toLowerCase()} </h4>
          <div className="template-number">{props.number}</div>
        </div>
        <div>
          <div className='info'>
            <h3> Category: {category} </h3>
          </div>
          <div className='info'>
            <h3> Token: {token} </h3>
          </div>
          <div className='info'>
            <h3> Key: {key} </h3>
          </div>
        </div>
        <TextArea
          disabled={disabled}
          variables={templatesStore.keys}
          text={text}
          editText={setText}
        />
        <div className="options">
          <Tooltip disableFocusListener title="Edit" placement="top">
            <Button variant="contained" color="primary" className="green" onClick={ () => setDisabled(false)}>
              <img src={editImg}/>
            </Button>
          </Tooltip>
          <Tooltip disableFocusListener title="Save" placement="top">
            <Button variant="contained" color="primary" className="green"
              onClick={save}>
              <img src={saveImg}/>
            </Button>
          </Tooltip>
          <Tooltip disableFocusListener title="Reset to Default" placement="top">
            <Button variant="contained" color="primary" className="red" 
            onClick={openResetModal}>
              <img src={resetImg}/>
            </Button>
          </Tooltip>
          <Modal 
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open} 
            onClose={() => setOpen(false)} 
            disableAutoFocus={true}
            disableEnforceFocus={true}
          >
            <div className="modal_container">
              <h3> Are you sure you want to delete this message template? </h3>
              <h5> This action cannot be undone. </h5>
              <div className="button_container">
                <Button variant="contained" color="primary" className="cancel" 
                onClick={() => setOpen(false)}> Cancel </Button> 
                <Button variant="contained" color="primary" className="red" 
                onClick={resetToDefault}
                > Confirm </Button> 
              </div>
            </div>
          </Modal>
          <Modal 
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={defaultOpen} 
            onClose={() => setDefaultOpen(false)} 
            disableAutoFocus={true}
            disableEnforceFocus={true}
          >
            <div className="modal_container">
              <h3> Sorry, you can not delete a default template. </h3>
              <div>
                <Button variant="contained" color="primary" onClick={() => setDefaultOpen(false)}> OK </Button> 
              </div>
            </div>
          </Modal>
        </div>
      </div> 
    </div>
  )
})

class TextArea extends Component {
  
  filter = (variables, typedWord) => {
		const location = typedWord.length
		const selectedvariables = []
		for (let i = 0; i < variables.length; i++){
			const key = variables[i]
      // word typed is longer than possible selection
			if (key.length < location) continue
			let match = true
			for( let j=0; j < location; j++){ // compare each letter up until the last typed letter
				if(typedWord[j] !== key[j]) match = false
				if(!match) break
			}
			if (match) selectedvariables.push(variables[i])
		}
		return selectedvariables
	}

  render = () => (
    <ReactTextareaAutocomplete
      className="editable_text"
      dropdownClassName="dropdown_text"
      disabled={this.props.disabled}
      minChar={0}
      value={this.props.text}
      onChange={event => {
        this.props.editText(event.target.value)
      }}
      loadingComponent={Loading}
      ref={rta => { this.rta = rta }}
      trigger={{
        "$": {
          dataProvider: typedWord => this.filter(this.props.variables, typedWord),
          component: Item,
          output: (item, trigger) => (trigger + item)
        }
      }}
    />
  )
}

export default Template