import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom'
import OktaSignInWidget from '../components/nonreuseable/OktaSignInWidget'
import { useOktaAuth } from '@okta/okta-react'

// import React, { useEffect, useState } from 'react';
// import { Redirect } from 'react-router-dom';
// import OktaSignInWidget from './OktaSignInWidget';
// import { useOktaAuth } from '@okta/okta-react';

const Login = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const [ sessionLookupPending, setSessionLookupPending ] = useState(true)
  const [ remoteSessionExists, setRemoteSessionExists ] = useState(false)

  useEffect(() => {
    // signIn.authClient.session.exists().then(function (sessionExists) {
    //   if (sessionExists) {
    //     signIn.authClient.token.getWithoutPrompt().then(function (response) {
    //       signIn.authClient.tokenManager.setTokens(response.tokens);
    //     });
    //   }
    // }
    oktaAuth.session.exists().then(function(exists) {
      if (exists) {
        setRemoteSessionExists(exists)
        console.log("logged in")
        // logged in
        oktaAuth.token.getWithRedirect({
          responseType: 'id_token', // or array of types
        })
        .then(function(res) {
          var tokens = res.tokens;
        
          // Do something with tokens, such as
          // authClient.tokenManager.setTokens(tokens);
        })
        .catch(function(err) {
          // handle OAuthError or AuthSdkError (AuthSdkError will be thrown if app is in OAuthCallback state)
        })
      } else {
        console.log("NOT LOGGED in")
        // not logged in
      }
      setSessionLookupPending(false)
    })
  }, [])
  
  const onSuccess = (tokens) => {
    console.log('successfully logging in with password')
    console.log(tokens)
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err) => {
    console.log('error logging in with password', err);
  };

  if (!authState || authState.isPending) return null;


  const result = sessionLookupPending ? <div>Pending session lookup</div> : 
    authState.isAuthenticated ? <Redirect to={{ pathname: '/' }}/> :
    remoteSessionExists ? <div> Pre-redirect to login/callback </div> :
    <OktaSignInWidget
      config={config}
      onSuccess={onSuccess}
      onError={onError}
    />
  
  return (
    <div className='login-page'>
      {/* {`sessionLookupPending: ${sessionLookupPending} \n|`}
      {`remoteSessionExists: ${remoteSessionExists} \n|`}
      {`authState.isAuthenticated: ${authState.isAuthenticated} \n|`} */}
      {result}
    </div>
  )
}
export default Login