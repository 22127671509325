import { Requests } from "./Requests"
import endpoints from '../../endpoints'

const ORGANIZATION_ID = 123

export class TemplatesHelper {
  /**
   * Fetch all variable keys to use in a template
   * (currently uses static global org id to fetch)
   * @returns {Array<Object>}
   */
  static async getKeys(){
    const res = await Requests.basicRequest(`${endpoints.TEMPLATES_LAMBDA_BASE}/keys/${ORGANIZATION_ID}`, "GET")
    const keys = []
    if(res !== undefined){
      for ( let i = 0; i < res.length; i++){
        keys.push(res[i].key.substr(1)) // Removes $ from keys
      }
    }
    return keys
  }

  /**
   * Fetch all templates for a given environment (determined by url)
   * @returns {Array<Object>}
   */
   static async getTemplates(orgName){
    const templates = await Requests.basicRequest(`${endpoints.TEMPLATES_LAMBDA_BASE}/org/${orgName}`, "GET")
    return (templates === undefined) ? [] : templates
  }
  
  /**
   * Create a template
   * @param {String} url
   * @param {String} category 
   * @param {String} filter 
   * @param {String} text 
   * @param {String} token 
   * //TODO: returns
   */
  static create(url, category, filter, text = "", token = ""){
    const payload = { "keyType": "orgName","key": category,
    "category": filter, text, token }
    return Requests.basicRequest(url, "POST", payload)
  }

  /**
   * Update the text field of a template
   * @param {String|Number} id 
   * @param {String} text 
   * TODO: returns
   */
  static async update(id, text){
    const payload = { id, text }
    return Requests.basicRequest(endpoints.TEMPLATES_LAMBDA_BASE, "PUT", payload)
  }

  /**
   * Copy a template
   * @param {String} type 
   * @param {String} organization 
   * @param {String} filter
   * @param {String} text
   * @param {String} token 
   * TODO: returns
   */
  static async copy(type, organization, category, text, token){
    const payload = { "keyType": type, "key": organization,
      "category" : [category], text, token }
    return Requests.jsonRequest(endpoints.TEMPLATES_LAMBDA_BASE, "POST", payload)
  }

  /**
   * Delete a template given a template id
   * @param {String|Number} id 
   */
  static async delete(id){
    return Requests.basicRequest(`${endpoints.TEMPLATES_LAMBDA_BASE}/${id}`, "DELETE")
  }

  /**
   * Prevents rendering of default templates with the same tokens as the selected templates
   * @param {Array<Object>} selectedTemplates
   * @param {Array<Object>} defaultTemplates
   */
  static generateTemplatetoRender(selectedTemplates, defaultTemplates){
    // const defaults = {}
    // for (let i = 0; i < defaultTemplates.length; i++){
    //   const template = defaultTemplates[i]
    //   defaults[template.token] = template
    // }

    const orgTemplates = {}
    for (let j = 0; j < selectedTemplates.length; j++){
      const template = selectedTemplates[j]
      orgTemplates[template.token] = template
    }
    const orgTokenList = Object.keys(orgTemplates)

    // Find default templates where the token is not in org templates
    const templatesToRender = []
    for (let i = 0; i < defaultTemplates.length; i++){
      const defaultTemplate = defaultTemplates[i]
      const defaultToken = defaultTemplate.token
      if (orgTokenList.includes(defaultToken)){
        const matchedTemplate = orgTemplates[defaultToken]
        matchedTemplate.color = "#DCDCDC"     
        templatesToRender.push(matchedTemplate)
      } else {
        templatesToRender.push(defaultTemplate)
      }
    }
    return templatesToRender
  } 

  /**
   * Filters templates based on selected organization name, category, workflow and token
   * @param {Array<Object>} templates 
   */
  static filter(templates, selectedCategory, selectedWorkflow, selectedToken){
    const filteredTemplates = []
    if (templates !== undefined){
      for(let i = 0; i < templates.length; i++){
        const template = templates[i]
        if (template === undefined)
          continue
        let validCategory = true
        let validToken = true
        let validWorkflow = true

        // Parse template token into workflow type and the rest of the token
        let token = template.token
        let workflow
        const splitter = token.split('.', 3)
        if (splitter.length === 1){
          workflow = "other"
          token = splitter[0]
        } else {
          workflow = splitter[0]
          token = splitter[1]
        }

        // Actual filtering of what templates should be rendered
        if (template.category[0] !== selectedCategory && selectedCategory !== "None")
          validCategory = false
        if (workflow !== selectedWorkflow && selectedWorkflow !== "None")
          validWorkflow = false
        if (token !== selectedToken && selectedToken !== "None") 
          validToken = false
        if (validCategory && validWorkflow && validToken){
          filteredTemplates.push(template)
        } 
      }
    }
    return filteredTemplates
  } 

  static removeItem(array, value){
    const index = array.indexOf(value)
    if (index > -1) {
      array.splice(index, 1)
    }
    return array
  }

}