import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

const ColoredSwitch = withStyles({
  switchBase: {
    color: '#7b7b7b',
    '&$checked': {
      color: '#0CA578',
    },
    '&$checked + $track': {
      backgroundColor: '#0CA578',
    },
  },
  checked: {},
  track: {},
})(Switch)


export const Toggle = ({ label, value, toggle=()=>{console.log('toggling')}, disabled }) => { 
  return (
    <FormGroup>
      <FormControlLabel
        control={<ColoredSwitch checked={value} onChange={() => toggle(!value)} name={label} />}
        label={label}
        disabled={disabled}
      />
    </FormGroup>
  )
}
