import React from 'react'
import { Grid, List, ListItem } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import PermissionsHelper from '../../utilities/PermissionsHelper'

const PermissionList = ({ loading, items=[], edit }) => {
  return (
    <div className="list-container">
    { 
      loading ? 
      <div className="permissions-list">
        <Grid item xs={12} md={6} className="list"  >
          <div className="loading-group"> 
              <CircularProgress disableShrink className="loading"/> 
          </div> 
        </Grid>
      </div>
      :
      <div className="permissions-list">
        <Grid item xs={12} md={6} className="list"  >
          <List >
            {items.sort(PermissionsHelper.sortItems('asc', 'permission')).map( p =>
              <ListItem 
                button
                className={'item'}
                key={p.permission}
                onClick={() => edit(p.permission, p.description)}
              >
                <div className='item-text'>
                  <div className='permission'> {p.permission} </div>
                  <div className='description'> {(p.description === '' || !p.description) ? 'No description' : p.description} </div>
                </div>
              </ListItem>
            )}
          </List>
        </Grid>
      </div>
    }
  </div>
  )
}

export default PermissionList