import React from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../hooks/useStores'

const FeatureFlags = observer(() => {

  const { featureFlagsStore } = useStores()

  return (
    <div className='organization-settings-container'>
      <div className='title'> {featureFlagsStore.title} </div>
      <div className='selection-container'> 
        <div className='dropdown-container'>
          
        </div>
        <div className='properties-container'>
          
        </div>
      </div>
    </div> 
  )
})

export default FeatureFlags