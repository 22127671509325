import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import OktaSignIn from '@okta/okta-signin-widget'
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'
import logo from '../../assets/marley.png'

export default class OktaSignInWidget extends Component {
  componentDidMount() {
    const el = ReactDOM.findDOMNode(this)
    this.widget = new OktaSignIn({
      baseUrl: this.props.config.baseUrl,
      clientId: this.props.config.clientId,
      authParams: {
        issuer: this.props.config.issuer,
        pkce: this.props.config.pkce || true
      },
      logo,
      features: {
        idpDiscovery: true,
        rememberMe: true,
      },
      idpDiscovery: {
        requestContext: window.location.href,
      },
      colors: {
        brand: '#F9685D'
      },
      redirectUri: this.props.config.redirectUri,
      language: 'en',
      i18n: {
        'en': {
          'primaryauth.title': process.env.ENVIRONMENT_PRETTY_NAME || ' ',
          'primaryauth.submit': 'Sign In',
          // More e.g. [primaryauth.username.placeholder,  primaryauth.password.placeholder, needhelp, etc.].
          // Full list here: https://github.com/okta/okta-signin-widget/blob/master/packages/@okta/i18n/dist/properties/login.properties
        }
      },
    })
    this.widget.renderEl({el}, this.props.onSuccess, this.props.onError)
  }

  componentWillUnmount() {
    this.widget.remove()
  }

  render() {
    return <div className='sign-in-widget'/>
  }
}
