import { makeAutoObservable } from "mobx"
import { getDadJoke } from '../utilities/Jokes'

export default class Home {

  joke = ''

  constructor(){
    makeAutoObservable(this)
  }

  getJoke = async () => {
    const joke = await getDadJoke()
    console.log(joke)
    this.joke = joke
  }

}