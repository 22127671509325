import React from "react"
import Template from "../reuseable/Template"
import { Error } from "../reuseable/Error"

const TemplateList = props => {
  const templates = props.templates

  let count = 0
  return (
    <ol className='template-list-container'>
      {(templates).map(template => {
        count ++
        return (
          <Template 
            key={template.id}
            template={template}
            number={count}
            reload={props.reload}
          />
        )
      })}
    </ol>
  )
}

export default TemplateList