import { makeAutoObservable } from "mobx"

export default class FeatureFlags {
  
  title = 'Feature Flags'

  constructor(){
    makeAutoObservable(this)
  }

}